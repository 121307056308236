/* The subnavigation menu */
.subnav {
	float: left;
	overflow: hidden;
}

/* Subnav button */
.subnav .subnavbtn {
	font-size: 16px;
	border: none;
	color: black;
	padding: 14px 16px;
	background-color: inherit;
	font-family: inherit;
	margin: 0;
}

/* Style the subnav content - positioned absolute */
.subnav-content {
	display: none;
	border-radius: 0px;
	position: absolute;
	left: 0;
	background-color: #dbd5d1;
	width: 100%;
	z-index: 1;
	height: 100px;
}

/* Style the subnav links */
.subnav-content a {
	float: left;
	color: #484b4c;
}

/* Add a grey background color on hover */
.subnav-content a:hover {
	background-color: #eee;
	color: black;
}


/* When you move the mouse over the subnav container, open the subnav content */
/* .subnav:hover .subnav-content {
	display:block;
}  */


.menu-product-item {
	background-color: #dbd5d1;
	width: 30px;
}

.thumbnailgallery {
	width: 100%;
	height: 90px;
	overflow: hidden;
	padding: 5px;
}

.showrooms a.logo {
	display: inline-block;
	width: 178px;
}

.showrooms a:hover {
	color: grey;
}

.showrooms img {
	display: block;
	padding: 10px;
}

.arrowleft,
.arrowright {
	font-size: 30px;
	cursor: pointer;
}

.img-icon {
	display: block;
	max-width: 70px;
	margin-left: auto;
	margin-right: auto;
}

.text-border{
	font-weight: lighter;
}