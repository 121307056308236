@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
@import url(//db.onlinewebfonts.com/c/7d1c0df7a7b61f4189d0ca451f707db0?family=Eras+Medium+ITC);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
	font-family: 'SF Pro Display Regular';
	font-style: normal;
	font-weight: normal;
	src: local('SF Pro Display Regular'), url(../../static/media/SFPRODISPLAYREGULAR.d52afba3.woff) format('woff');
}


@font-face {
	font-family: 'SF Pro Display Ultralight Italic';
	font-style: normal;
	font-weight: normal;
	src: local('SF Pro Display Ultralight Italic'),
	url(../../static/media/SFPRODISPLAYULTRALIGHTITALIC.68284f1f.woff) format('woff');
}


@font-face {
	font-family: 'SF Pro Display Thin Italic';
	font-style: normal;
	font-weight: normal;
	src: local('SF Pro Display Thin Italic'),
	url(../../static/media/SFPRODISPLAYTHINITALIC.3aac11c8.woff) format('woff');
}


@font-face {
	font-family: 'SF Pro Display Light Italic';
	font-style: normal;
	font-weight: normal;
	src: local('SF Pro Display Light Italic'),
	url(../../static/media/SFPRODISPLAYLIGHTITALIC.9b041dd4.woff) format('woff');
}


@font-face {
	font-family: 'SF Pro Display Medium';
	font-style: normal;
	font-weight: normal;
	src: local('SF Pro Display Medium'),
	url(../../static/media/SFPRODISPLAYMEDIUM.101ed521.woff) format('woff');
}


@font-face {
	font-family: 'SF Pro Display Semibold Italic';
	font-style: normal;
	font-weight: normal;
	src: local('SF Pro Display Semibold Italic'),
	url(../../static/media/SFPRODISPLAYSEMIBOLDITALIC.8a49e0ab.woff) format('woff');
}


@font-face {
	font-family: 'SF Pro Display Bold';
	font-style: normal;
	font-weight: normal;
	src: local('SF Pro Display Bold'),
	url(../../static/media/SFPRODISPLAYBOLD.7f4a2756.woff) format('woff');
}


@font-face {
	font-family: 'SF Pro Display Heavy Italic';
	font-style: normal;
	font-weight: normal;
	src: local('SF Pro Display Heavy Italic'),
	url(../../static/media/SFPRODISPLAYHEAVYITALIC.2c773a51.woff) format('woff');
}


@font-face {
	font-family: 'SF Pro Display Black Italic';
	font-style: normal;
	font-weight: normal;
	src: local('SF Pro Display Black Italic'),
	url(../../static/media/SFPRODISPLAYBLACKITALIC.0156863e.woff) format('woff');
}

body {
	font-family: 'Poppins',
	sans-serif;
}

.App-header {
  color: white;
	min-height: 50vh;
	text-align: left;
	min-width: 60vh;
}

.App-header-page {
	color: white;
	min-height: 30vh;
	text-align: left;
	min-width: 60vh;
}

.home-img-padding{
	padding-bottom: 20px;
}

.App-header-logo {
	/* background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin); */
	background-color: white;
	color: white;
	min-height: 10vh;
	padding-bottom: 1rem;
	text-align: center;
	min-width: 60vh;
	top: 10px;
}

.App-container-min{
	padding-top:0em;
	padding-bottom: 1em;
}

.menu-max-contain{
	padding-left: 150px;
}

.app-home{
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.App-about{
	background-color: #efeff0;
}

.App-menu{
	background-color: #f8f9fa;
}

.App-footer{
	/* background-color: #f8f9fa; */
	color: black;
	min-height: 20vh;
	padding-top: 1rem;
}

.App-container{
	min-height: 30vh;
	padding: 1rem 4rem;
	text-align: center;
	min-width: 60vh;
}

.App-link {
  color: #61dafb;
}

.affix {
	top: 0;
	width: 100%;
}

.affix+.container-fluid {
	padding-top: 70px;
}

.short-div {
	height: 60px;
	text-align: right;
}

/*-----Home------*/
.home-card{
	min-height: 500px;
}

/*-----Header------*/
.img-logo {
	display: block;
	max-width: 100%;
	min-width: 20vh;
}

.footer-fb-logo{
	margin-bottom: 3px;
}

/*-----Contact------*/
.contact-map{
	margin-top: 10px;
}

.contact-email-form{
	margin:20px;
	text-align: left;
}

/*-----Menu------*/
.menu-search-popup{
	z-index: 1;
}

.img-logo-menu {
	display: inline-block;
	white-space: nowrap;
	max-width: 20%;
}

.block-logo-menu {
	background-color: #b2babb;
	align-items: center;
	box-shadow: 5px 5px 8px #888888;
}

.menu-popup{
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	/* IE 8 */
	filter: alpha(opacity=100);
	/* IE 5-7 */
	-moz-opacity: 1;
	/* Netscape */
	-khtml-opacity: 1;
	/* Safari 1.x */
	opacity: 1;
	/* Good browsers */
}

.menu-product{
	margin-top: 20px;
}

/*-----Footer------*/
.footer-social-icon{
	padding-bottom: 1rem;
}

.footer-copyright{
	padding-top: 1rem;
	padding-bottom: 2rem;
}

/*-----Button------*/
.btn_gettouch {
	position: fixed !important;
	width: 70px;
	max-height: 90px;
	top: 40%;
	right: 0px;
	font-size: 12px;
	/* padding: 10px 17px; */
	text-align: center;
	z-index: 1;
}

.btn_gotop {
	position: fixed !important;
	width: 50px;
	height: 50px;
	bottom: 2%;
	right: 15px;
	font-size: 12px;
	/* padding: 10px 17px; */
	text-align: center;
	z-index: 1;
}

.navbar-nav>li {
	margin-left: 15px;
	margin-right: 15px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a:link,
a:visited {
	text-decoration: none;
}

.products-container{
	text-align: center;
	min-width: 400px;
	margin-bottom: 20px;
}

.modalPopup-div{
	text-align: left;
}

.header-font-head{
	font-size: 50px;
}

.products-group-menu{
	min-height: 110px;
	min-width: 100px;
	margin: 10px;
}

.card-bg-collor{
	background-color: #8fbc8f;
	border-radius:2%;
}

.hyperlink-color{
	color: black;
}

.hyperlink-product {
	color: black;
}

.menu-bg-color{
	background-color: #edeceb;
	border-radius:10px;
	min-height: 50px;
}

.popovermenu-box-size{
	width: 70px;
	height: 77px;
	background-color: #d2d0ce;
	display: block;
	padding: 12px 0;
}
.popovermenu-box-size-feedback {
	width: 70px;
	height: 70px;
	background-color: #40bf80;
	display: block;
	padding: 12px 0;
}
.home-cer-header{
	align-items: center;
	text-align: center;
}
.home-cer-1{
	max-width: 65%;
	align-items: center;
	text-align: center;
}
.home-min-width{
	min-width: 200px;
	padding-top: 20px;
}
.home-min-width-1{
	min-width: 320px;
	padding-top: 20px;
}
.home-min-width-2{
	min-width: 170px;
	padding-top: 20px;
}
.home-min-width-3{
	min-width: 350px;
	padding-top: 20px;
}

.header-content-indent{
	text-indent: 50px;
}

.header-content-bold{
	font-weight: 700;
	font-family: 'Eras Medium ITC';
}

.header-content-border{
	padding: 10px;
	padding-left: 30px;
	border-radius: 60px 20px;
	border-radius: 60px 20px;
	background-color: cadetblue;
	opacity: 0.9;
}

.home-header-content {
	padding-left: 5px;
	border-radius: 0px 0px 30px 0px;
	background-color: cadetblue;
	opacity: 0.9;
}

.home-bold{
	font-weight: bold;
	font-size: 24px;
}

#nav {
	background-color: #333;
	position: fixed;
	left: 0;
	width: 100%;
	display: block;
	transition: top 0.5s;
	z-index: 50;
}





.main-timeline7 {
	overflow: hidden;
	position: relative
}

.main-timeline7 .timeline {
	width: 50%;
	float: left;
	z-index: 1;
	position: relative
}

.main-timeline7 .timeline:after,
.main-timeline7 .timeline:before {
	content: "";
	display: block;
	clear: both
}

.main-timeline7 .timeline:before {
	content: "";
	width: 40px;
	height: 90%;
	background: #ecebbd;
	position: absolute;
	top: 10%;
	right: -20px
}

.main-timeline7 .timeline:last-child:before {
	height: 0
}

.main-timeline7 .timeline-icon {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background: #ecebbd;
	overflow: hidden;
	text-align: center;
	position: absolute;
	top: 0;
	right: -30px;
	z-index: 3
}

.main-timeline7 .timeline-icon:before {
	content: "";
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: #fff;
	border: 2px solid #ecebbd;
	box-shadow: 0 0 0 4px #303a3b;
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.main-timeline7 .timeline-icon i {
	font-size: 35px;
	color: #303a3b;
	line-height: 80px;
	z-index: 1;
	position: relative
}

.main-timeline7 .year {
	display: block;
	padding: 0 60px 0 30px;
	font-size: 22px;
	color: #303a3b;
	text-align: right;
	border-bottom: 2px solid #303a3b;
	z-index: 2;
	position: relative
}

.main-timeline7 .year:before {
	content: "";
	display: block;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background: #ecebbd;
	border: 5px solid #fff;
	box-shadow: 0 0 0 4px #ecebbd;
	margin: auto;
	position: absolute;
	bottom: -15px;
	left: 4px
}

.main-timeline7 .year:after {
	content: "";
	border-left: 10px solid #303a3b;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	position: absolute;
	bottom: -11px;
	left: 50px
}

.main-timeline7 .timeline-content {
	padding: 18px 60px 18px 40px;
	text-align: right;
	position: relative;
	z-index: 1
}

.main-timeline7 .timeline-content:after,
.main-timeline7 .timeline-content:before {
	content: "";
	width: 80px;
	height: 80px;
	border-radius: 50%;
	background: #fff;
	position: absolute;
	top: -7%;
	right: 15px;
	z-index: -1
}

.main-timeline7 .timeline-content:after {
	left: auto;
	right: -95px
}

.main-timeline7 .timeline:last-child .timeline-content:after,
.main-timeline7 .timeline:last-child .timeline-content:before {
	width: 0;
	height: 0;
}

.main-timeline7 .title {
	font-size: 14px;
	font-weight: 700;
	color: #303a3b;
	margin-top: 0;
}

.main-timeline7 .description {
	font-size: 15px;
	color: #7f8386;
	line-height: 25px;
}

/* ความสูงของ Line */
.main-timeline7 .timeline:nth-child(2) {
	margin-top: 80px;
}
/* ความสูงของ Line */
.main-timeline7 .timeline:nth-child(even) {
	margin-bottom: 40px;
}
/* ความสูงของ Line */
.main-timeline7 .timeline:nth-child(odd) {
	margin: -100px 0 0;
}

/* Margin botton */
.main-timeline7 .timeline:first-child,
.main-timeline7 .timeline:last-child:nth-child(even) {
	margin: 0 !important
}

.main-timeline7 .timeline:nth-child(2n) .timeline-icon,
.main-timeline7 .timeline:nth-child(2n):before {
	right: auto;
	left: -20px;
	background: #afe1af;
}

.main-timeline7 .timeline:nth-child(2n) .timeline-icon {
	left: -30px;
}

.main-timeline7 .timeline:nth-child(2n) .year {
	padding: 0 30px 0 60px;
	text-align: left;
}

.main-timeline7 .timeline:nth-child(2n) .year:before {
	left: auto;
	right: 3px;
}

.main-timeline7 .timeline:nth-child(2n) .year:after {
	border-left: none;
	border-right: 10px solid #303a3b;
	right: 50px;
}

.main-timeline7 .timeline:nth-child(2n) .timeline-content {
	padding: 18px 40px 18px 60px;
	text-align: left;
}

.main-timeline7 .timeline:nth-child(2n) .timeline-content:before {
	left: -95px;
}

.main-timeline7 .timeline:nth-child(2n) .timeline-content:after {
	left: 15px
}

.main-timeline7 .timeline:nth-child(2n) .timeline-icon:before {
	border-color: #afe1af;
	box-shadow: 0 0 0 4px #303a3b;
}

.main-timeline7 .timeline:nth-child(2n) .year:before {
	background: #afe1af;
	box-shadow: 0 0 0 4px #afe1af;
}

.main-timeline7 .timeline:nth-child(2n) .title {
	color: #303a3b;
}

.main-timeline7 .timeline:nth-child(3n) .timeline-icon,
.main-timeline7 .timeline:nth-child(3n):before {
	background: #8fbc8f;
}

.main-timeline7 .timeline:nth-child(3n) .timeline-icon:before {
	border-color: #8fbc8f;
	box-shadow: 0 0 0 4px #303a3b;
}

.main-timeline7 .timeline:nth-child(3n) .year:before {
	background: #8fbc8f;
	box-shadow: 0 0 0 4px #8fbc8f;
}

.main-timeline7 .timeline:nth-child(3n) .title {
	color: #303a3b;
}

.main-timeline7 .timeline:nth-child(4n) .timeline-icon,
.main-timeline7 .timeline:nth-child(4n):before {
	background: #A9A9A9
}

.main-timeline7 .timeline:nth-child(4n) .timeline-icon:before {
	border-color: #A9A9A9;
	box-shadow: 0 0 0 4px #303a3b;
}

.main-timeline7 .timeline:nth-child(4n) .year:before {
	background: #A9A9A9;
	box-shadow: 0 0 0 4px #A9A9A9;
}

.main-timeline7 .timeline:nth-child(4n) .title {
	color: #303a3b
}

@media only screen and (max-width:990px) {
	.main-timeline7 .timeline {
		width: 100%
	}

	.main-timeline7 .timeline:nth-child(even),
	.main-timeline7 .timeline:nth-child(odd) {
		margin: 0
	}

	.main-timeline7 .timeline:before,
	.main-timeline7 .timeline:nth-child(2n):before {
		width: 30px;
		height: 100%;
		left: 25px
	}

	.main-timeline7 .timeline-icon,
	.main-timeline7 .timeline:nth-child(2n) .timeline-icon {
		left: 0
	}

	.main-timeline7 .timeline:nth-child(2n) .year,
	.main-timeline7 .year {
		text-align: left;
		padding: 0 30px 0 100px
	}

	.main-timeline7 .timeline:nth-child(2n) .year:before,
	.main-timeline7 .year:before {
		left: auto;
		right: 4px
	}

	.main-timeline7 .year:after {
		left: auto;
		right: 50px;
		border-right: 10px solid #303a3b;
		border-left: none
	}

	.main-timeline7 .timeline-content .description {
		color: #666
	}

	.main-timeline7 .timeline-content,
	.main-timeline7 .timeline:nth-child(2n) .timeline-content {
		text-align: left;
		padding: 18px 40px 18px 100px
	}

	.main-timeline7 .timeline-content:after,
	.main-timeline7 .timeline-content:before {
		width: 0;
		height: 0
	}
}

a{text-decoration:none}
h4{text-align:center;margin:30px 0;color:#444}
.main-timeline{position:relative}
.main-timeline:before{content:"";width:5px;height:100%;border-radius:20px;margin:0 auto;background:#242922;position:absolute;top:0;left:0;right:0}
.main-timeline .timeline{display:inline-block;margin-bottom:50px;position:relative}
.main-timeline .timeline:before{content:"";width:20px;height:20px;border-radius:50%;border:4px solid #fff;background:#ec496e;position:absolute;top:50%;left:50%;z-index:1;transform:translate(-50%,-50%)}
.main-timeline .timeline-icon{display:inline-block;width:130px;height:130px;border-radius:50%;border:3px solid #ec496e;padding:13px;text-align:center;position:absolute;top:50%;left:30%;transform:translateY(-50%)}
.main-timeline .timeline-icon i{display:block;border-radius:50%;background:#ec496e;font-size:64px;color:#fff;line-height:100px;z-index:1;position:relative}
.main-timeline .timeline-icon:after,.main-timeline .timeline-icon:before{content:"";width:100px;height:4px;background:#ec496e;position:absolute;top:50%;right:-100px;transform:translateY(-50%)}
.main-timeline .timeline-icon:after{width:70px;height:50px;background:#fff;top:89px;right:-30px}
.main-timeline .timeline-content{width:50%;padding:0 50px;margin:52px 0 0;float:right;position:relative}
.main-timeline .timeline-content:before{content:"";width:70%;height:100%;border:3px solid #ec496e;border-top:none;border-right:none;position:absolute;bottom:-13px;left:35px}
.main-timeline .timeline-content:after{content:"";width:37px;height:3px;background:#ec496e;position:absolute;top:13px;left:0}
.main-timeline .title{font-size:20px;font-weight:600;color:#ec496e;text-transform:uppercase;margin:0 0 5px}
.main-timeline .description{display:inline-block;font-size:16px;color:#404040;line-height:20px;letter-spacing:1px;margin:0}
.main-timeline .timeline:nth-child(even) .timeline-icon{left:auto;right:30%}
.main-timeline .timeline:nth-child(even) .timeline-icon:before{right:auto;left:-100px}
.main-timeline .timeline:nth-child(even) .timeline-icon:after{right:auto;left:-30px}
.main-timeline .timeline:nth-child(even) .timeline-content{float:left}
.main-timeline .timeline:nth-child(even) .timeline-content:before{left:auto;right:35px;transform:rotateY(180deg)}
.main-timeline .timeline:nth-child(even) .timeline-content:after{left:auto;right:0}
.main-timeline .timeline:nth-child(2n) .timeline-content:after,.main-timeline .timeline:nth-child(2n) .timeline-icon i,.main-timeline .timeline:nth-child(2n) .timeline-icon:before,.main-timeline .timeline:nth-child(2n):before{background:#f9850f}
.main-timeline .timeline:nth-child(2n) .timeline-icon{border-color:#f9850f}
.main-timeline .timeline:nth-child(2n) .title{color:#f9850f}
.main-timeline .timeline:nth-child(2n) .timeline-content:before{border-left-color:#f9850f;border-bottom-color:#f9850f}
.main-timeline .timeline:nth-child(3n) .timeline-content:after,.main-timeline .timeline:nth-child(3n) .timeline-icon i,.main-timeline .timeline:nth-child(3n) .timeline-icon:before,.main-timeline .timeline:nth-child(3n):before{background:#8fb800}
.main-timeline .timeline:nth-child(3n) .timeline-icon{border-color:#8fb800}
.main-timeline .timeline:nth-child(3n) .title{color:#8fb800}
.main-timeline .timeline:nth-child(3n) .timeline-content:before{border-left-color:#8fb800;border-bottom-color:#8fb800}
.main-timeline .timeline:nth-child(4n) .timeline-content:after,.main-timeline .timeline:nth-child(4n) .timeline-icon i,.main-timeline .timeline:nth-child(4n) .timeline-icon:before,.main-timeline .timeline:nth-child(4n):before{background:#2fcea5}
.main-timeline .timeline:nth-child(4n) .timeline-icon{border-color:#2fcea5}
.main-timeline .timeline:nth-child(4n) .title{color:#2fcea5}
.main-timeline .timeline:nth-child(4n) .timeline-content:before{border-left-color:#2fcea5;border-bottom-color:#2fcea5}
@media only screen and (max-width:1200px){.main-timeline .timeline-icon:before{width:50px;right:-50px}
.main-timeline .timeline:nth-child(even) .timeline-icon:before{right:auto;left:-50px}
.main-timeline .timeline-content{margin-top:75px}
}
@media only screen and (max-width:990px){.main-timeline .timeline{margin:0 0 10px}
.main-timeline .timeline-icon{left:25%}
.main-timeline .timeline:nth-child(even) .timeline-icon{right:25%}
.main-timeline .timeline-content{margin-top:115px}
}
@media only screen and (max-width:767px){.main-timeline{padding-top:50px}
.main-timeline:before{left:80px;right:0;margin:0}
.main-timeline .timeline{margin-bottom:70px}
.main-timeline .timeline:before{top:0;left:83px;right:0;margin:0}
.main-timeline .timeline-icon{width:60px;height:60px;line-height:40px;padding:5px;top:0;left:0}
.main-timeline .timeline:nth-child(even) .timeline-icon{left:0;right:auto}
.main-timeline .timeline-icon:before,.main-timeline .timeline:nth-child(even) .timeline-icon:before{width:25px;left:auto;right:-25px}
.main-timeline .timeline-icon:after,.main-timeline .timeline:nth-child(even) .timeline-icon:after{width:25px;height:30px;top:44px;left:auto;right:-5px}
.main-timeline .timeline-icon i{font-size:30px;line-height:45px}
.main-timeline .timeline-content,.main-timeline .timeline:nth-child(even) .timeline-content{width:100%;margin-top:-15px;padding-left:130px;padding-right:5px}
.main-timeline .timeline:nth-child(even) .timeline-content{float:right}
.main-timeline .timeline-content:before,.main-timeline .timeline:nth-child(even) .timeline-content:before{width:50%;left:120px}
.main-timeline .timeline:nth-child(even) .timeline-content:before{right:auto;transform:rotateY(0)}
.main-timeline .timeline-content:after,.main-timeline .timeline:nth-child(even) .timeline-content:after{left:85px}
}
@media only screen and (max-width:479px){.main-timeline .timeline-content,.main-timeline .timeline:nth-child(2n) .timeline-content{padding-left:110px}
.main-timeline .timeline-content:before,.main-timeline .timeline:nth-child(2n) .timeline-content:before{left:99px}
.main-timeline .timeline-content:after,.main-timeline .timeline:nth-child(2n) .timeline-content:after{left:65px}
}
/* The subnavigation menu */
.subnav {
	float: left;
	overflow: hidden;
}

/* Subnav button */
.subnav .subnavbtn {
	font-size: 16px;
	border: none;
	color: black;
	padding: 14px 16px;
	background-color: inherit;
	font-family: inherit;
	margin: 0;
}

/* Style the subnav content - positioned absolute */
.subnav-content {
	display: none;
	border-radius: 0px;
	position: absolute;
	left: 0;
	background-color: #dbd5d1;
	width: 100%;
	z-index: 1;
	height: 100px;
}

/* Style the subnav links */
.subnav-content a {
	float: left;
	color: #484b4c;
}

/* Add a grey background color on hover */
.subnav-content a:hover {
	background-color: #eee;
	color: black;
}


/* When you move the mouse over the subnav container, open the subnav content */
/* .subnav:hover .subnav-content {
	display:block;
}  */


.menu-product-item {
	background-color: #dbd5d1;
	width: 30px;
}

.thumbnailgallery {
	width: 100%;
	height: 90px;
	overflow: hidden;
	padding: 5px;
}

.showrooms a.logo {
	display: inline-block;
	width: 178px;
}

.showrooms a:hover {
	color: grey;
}

.showrooms img {
	display: block;
	padding: 10px;
}

.arrowleft,
.arrowright {
	font-size: 30px;
	cursor: pointer;
}

.img-icon {
	display: block;
	max-width: 70px;
	margin-left: auto;
	margin-right: auto;
}

.text-border{
	font-weight: lighter;
}
