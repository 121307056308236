.main-timeline7 {
	overflow: hidden;
	position: relative
}

.main-timeline7 .timeline {
	width: 50%;
	float: left;
	z-index: 1;
	position: relative
}

.main-timeline7 .timeline:after,
.main-timeline7 .timeline:before {
	content: "";
	display: block;
	clear: both
}

.main-timeline7 .timeline:before {
	content: "";
	width: 40px;
	height: 90%;
	background: #ecebbd;
	position: absolute;
	top: 10%;
	right: -20px
}

.main-timeline7 .timeline:last-child:before {
	height: 0
}

.main-timeline7 .timeline-icon {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background: #ecebbd;
	overflow: hidden;
	text-align: center;
	position: absolute;
	top: 0;
	right: -30px;
	z-index: 3
}

.main-timeline7 .timeline-icon:before {
	content: "";
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: #fff;
	border: 2px solid #ecebbd;
	box-shadow: 0 0 0 4px #303a3b;
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.main-timeline7 .timeline-icon i {
	font-size: 35px;
	color: #303a3b;
	line-height: 80px;
	z-index: 1;
	position: relative
}

.main-timeline7 .year {
	display: block;
	padding: 0 60px 0 30px;
	font-size: 22px;
	color: #303a3b;
	text-align: right;
	border-bottom: 2px solid #303a3b;
	z-index: 2;
	position: relative
}

.main-timeline7 .year:before {
	content: "";
	display: block;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background: #ecebbd;
	border: 5px solid #fff;
	box-shadow: 0 0 0 4px #ecebbd;
	margin: auto;
	position: absolute;
	bottom: -15px;
	left: 4px
}

.main-timeline7 .year:after {
	content: "";
	border-left: 10px solid #303a3b;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	position: absolute;
	bottom: -11px;
	left: 50px
}

.main-timeline7 .timeline-content {
	padding: 18px 60px 18px 40px;
	text-align: right;
	position: relative;
	z-index: 1
}

.main-timeline7 .timeline-content:after,
.main-timeline7 .timeline-content:before {
	content: "";
	width: 80px;
	height: 80px;
	border-radius: 50%;
	background: #fff;
	position: absolute;
	top: -7%;
	right: 15px;
	z-index: -1
}

.main-timeline7 .timeline-content:after {
	left: auto;
	right: -95px
}

.main-timeline7 .timeline:last-child .timeline-content:after,
.main-timeline7 .timeline:last-child .timeline-content:before {
	width: 0;
	height: 0;
}

.main-timeline7 .title {
	font-size: 14px;
	font-weight: 700;
	color: #303a3b;
	margin-top: 0;
}

.main-timeline7 .description {
	font-size: 15px;
	color: #7f8386;
	line-height: 25px;
}

/* ความสูงของ Line */
.main-timeline7 .timeline:nth-child(2) {
	margin-top: 80px;
}
/* ความสูงของ Line */
.main-timeline7 .timeline:nth-child(even) {
	margin-bottom: 40px;
}
/* ความสูงของ Line */
.main-timeline7 .timeline:nth-child(odd) {
	margin: -100px 0 0;
}

/* Margin botton */
.main-timeline7 .timeline:first-child,
.main-timeline7 .timeline:last-child:nth-child(even) {
	margin: 0 !important
}

.main-timeline7 .timeline:nth-child(2n) .timeline-icon,
.main-timeline7 .timeline:nth-child(2n):before {
	right: auto;
	left: -20px;
	background: #afe1af;
}

.main-timeline7 .timeline:nth-child(2n) .timeline-icon {
	left: -30px;
}

.main-timeline7 .timeline:nth-child(2n) .year {
	padding: 0 30px 0 60px;
	text-align: left;
}

.main-timeline7 .timeline:nth-child(2n) .year:before {
	left: auto;
	right: 3px;
}

.main-timeline7 .timeline:nth-child(2n) .year:after {
	border-left: none;
	border-right: 10px solid #303a3b;
	right: 50px;
}

.main-timeline7 .timeline:nth-child(2n) .timeline-content {
	padding: 18px 40px 18px 60px;
	text-align: left;
}

.main-timeline7 .timeline:nth-child(2n) .timeline-content:before {
	left: -95px;
}

.main-timeline7 .timeline:nth-child(2n) .timeline-content:after {
	left: 15px
}

.main-timeline7 .timeline:nth-child(2n) .timeline-icon:before {
	border-color: #afe1af;
	box-shadow: 0 0 0 4px #303a3b;
}

.main-timeline7 .timeline:nth-child(2n) .year:before {
	background: #afe1af;
	box-shadow: 0 0 0 4px #afe1af;
}

.main-timeline7 .timeline:nth-child(2n) .title {
	color: #303a3b;
}

.main-timeline7 .timeline:nth-child(3n) .timeline-icon,
.main-timeline7 .timeline:nth-child(3n):before {
	background: #8fbc8f;
}

.main-timeline7 .timeline:nth-child(3n) .timeline-icon:before {
	border-color: #8fbc8f;
	box-shadow: 0 0 0 4px #303a3b;
}

.main-timeline7 .timeline:nth-child(3n) .year:before {
	background: #8fbc8f;
	box-shadow: 0 0 0 4px #8fbc8f;
}

.main-timeline7 .timeline:nth-child(3n) .title {
	color: #303a3b;
}

.main-timeline7 .timeline:nth-child(4n) .timeline-icon,
.main-timeline7 .timeline:nth-child(4n):before {
	background: #A9A9A9
}

.main-timeline7 .timeline:nth-child(4n) .timeline-icon:before {
	border-color: #A9A9A9;
	box-shadow: 0 0 0 4px #303a3b;
}

.main-timeline7 .timeline:nth-child(4n) .year:before {
	background: #A9A9A9;
	box-shadow: 0 0 0 4px #A9A9A9;
}

.main-timeline7 .timeline:nth-child(4n) .title {
	color: #303a3b
}

@media only screen and (max-width:990px) {
	.main-timeline7 .timeline {
		width: 100%
	}

	.main-timeline7 .timeline:nth-child(even),
	.main-timeline7 .timeline:nth-child(odd) {
		margin: 0
	}

	.main-timeline7 .timeline:before,
	.main-timeline7 .timeline:nth-child(2n):before {
		width: 30px;
		height: 100%;
		left: 25px
	}

	.main-timeline7 .timeline-icon,
	.main-timeline7 .timeline:nth-child(2n) .timeline-icon {
		left: 0
	}

	.main-timeline7 .timeline:nth-child(2n) .year,
	.main-timeline7 .year {
		text-align: left;
		padding: 0 30px 0 100px
	}

	.main-timeline7 .timeline:nth-child(2n) .year:before,
	.main-timeline7 .year:before {
		left: auto;
		right: 4px
	}

	.main-timeline7 .year:after {
		left: auto;
		right: 50px;
		border-right: 10px solid #303a3b;
		border-left: none
	}

	.main-timeline7 .timeline-content .description {
		color: #666
	}

	.main-timeline7 .timeline-content,
	.main-timeline7 .timeline:nth-child(2n) .timeline-content {
		text-align: left;
		padding: 18px 40px 18px 100px
	}

	.main-timeline7 .timeline-content:after,
	.main-timeline7 .timeline-content:before {
		width: 0;
		height: 0
	}
}

a{text-decoration:none}
h4{text-align:center;margin:30px 0;color:#444}
.main-timeline{position:relative}
.main-timeline:before{content:"";width:5px;height:100%;border-radius:20px;margin:0 auto;background:#242922;position:absolute;top:0;left:0;right:0}
.main-timeline .timeline{display:inline-block;margin-bottom:50px;position:relative}
.main-timeline .timeline:before{content:"";width:20px;height:20px;border-radius:50%;border:4px solid #fff;background:#ec496e;position:absolute;top:50%;left:50%;z-index:1;transform:translate(-50%,-50%)}
.main-timeline .timeline-icon{display:inline-block;width:130px;height:130px;border-radius:50%;border:3px solid #ec496e;padding:13px;text-align:center;position:absolute;top:50%;left:30%;transform:translateY(-50%)}
.main-timeline .timeline-icon i{display:block;border-radius:50%;background:#ec496e;font-size:64px;color:#fff;line-height:100px;z-index:1;position:relative}
.main-timeline .timeline-icon:after,.main-timeline .timeline-icon:before{content:"";width:100px;height:4px;background:#ec496e;position:absolute;top:50%;right:-100px;transform:translateY(-50%)}
.main-timeline .timeline-icon:after{width:70px;height:50px;background:#fff;top:89px;right:-30px}
.main-timeline .timeline-content{width:50%;padding:0 50px;margin:52px 0 0;float:right;position:relative}
.main-timeline .timeline-content:before{content:"";width:70%;height:100%;border:3px solid #ec496e;border-top:none;border-right:none;position:absolute;bottom:-13px;left:35px}
.main-timeline .timeline-content:after{content:"";width:37px;height:3px;background:#ec496e;position:absolute;top:13px;left:0}
.main-timeline .title{font-size:20px;font-weight:600;color:#ec496e;text-transform:uppercase;margin:0 0 5px}
.main-timeline .description{display:inline-block;font-size:16px;color:#404040;line-height:20px;letter-spacing:1px;margin:0}
.main-timeline .timeline:nth-child(even) .timeline-icon{left:auto;right:30%}
.main-timeline .timeline:nth-child(even) .timeline-icon:before{right:auto;left:-100px}
.main-timeline .timeline:nth-child(even) .timeline-icon:after{right:auto;left:-30px}
.main-timeline .timeline:nth-child(even) .timeline-content{float:left}
.main-timeline .timeline:nth-child(even) .timeline-content:before{left:auto;right:35px;transform:rotateY(180deg)}
.main-timeline .timeline:nth-child(even) .timeline-content:after{left:auto;right:0}
.main-timeline .timeline:nth-child(2n) .timeline-content:after,.main-timeline .timeline:nth-child(2n) .timeline-icon i,.main-timeline .timeline:nth-child(2n) .timeline-icon:before,.main-timeline .timeline:nth-child(2n):before{background:#f9850f}
.main-timeline .timeline:nth-child(2n) .timeline-icon{border-color:#f9850f}
.main-timeline .timeline:nth-child(2n) .title{color:#f9850f}
.main-timeline .timeline:nth-child(2n) .timeline-content:before{border-left-color:#f9850f;border-bottom-color:#f9850f}
.main-timeline .timeline:nth-child(3n) .timeline-content:after,.main-timeline .timeline:nth-child(3n) .timeline-icon i,.main-timeline .timeline:nth-child(3n) .timeline-icon:before,.main-timeline .timeline:nth-child(3n):before{background:#8fb800}
.main-timeline .timeline:nth-child(3n) .timeline-icon{border-color:#8fb800}
.main-timeline .timeline:nth-child(3n) .title{color:#8fb800}
.main-timeline .timeline:nth-child(3n) .timeline-content:before{border-left-color:#8fb800;border-bottom-color:#8fb800}
.main-timeline .timeline:nth-child(4n) .timeline-content:after,.main-timeline .timeline:nth-child(4n) .timeline-icon i,.main-timeline .timeline:nth-child(4n) .timeline-icon:before,.main-timeline .timeline:nth-child(4n):before{background:#2fcea5}
.main-timeline .timeline:nth-child(4n) .timeline-icon{border-color:#2fcea5}
.main-timeline .timeline:nth-child(4n) .title{color:#2fcea5}
.main-timeline .timeline:nth-child(4n) .timeline-content:before{border-left-color:#2fcea5;border-bottom-color:#2fcea5}
@media only screen and (max-width:1200px){.main-timeline .timeline-icon:before{width:50px;right:-50px}
.main-timeline .timeline:nth-child(even) .timeline-icon:before{right:auto;left:-50px}
.main-timeline .timeline-content{margin-top:75px}
}
@media only screen and (max-width:990px){.main-timeline .timeline{margin:0 0 10px}
.main-timeline .timeline-icon{left:25%}
.main-timeline .timeline:nth-child(even) .timeline-icon{right:25%}
.main-timeline .timeline-content{margin-top:115px}
}
@media only screen and (max-width:767px){.main-timeline{padding-top:50px}
.main-timeline:before{left:80px;right:0;margin:0}
.main-timeline .timeline{margin-bottom:70px}
.main-timeline .timeline:before{top:0;left:83px;right:0;margin:0}
.main-timeline .timeline-icon{width:60px;height:60px;line-height:40px;padding:5px;top:0;left:0}
.main-timeline .timeline:nth-child(even) .timeline-icon{left:0;right:auto}
.main-timeline .timeline-icon:before,.main-timeline .timeline:nth-child(even) .timeline-icon:before{width:25px;left:auto;right:-25px}
.main-timeline .timeline-icon:after,.main-timeline .timeline:nth-child(even) .timeline-icon:after{width:25px;height:30px;top:44px;left:auto;right:-5px}
.main-timeline .timeline-icon i{font-size:30px;line-height:45px}
.main-timeline .timeline-content,.main-timeline .timeline:nth-child(even) .timeline-content{width:100%;margin-top:-15px;padding-left:130px;padding-right:5px}
.main-timeline .timeline:nth-child(even) .timeline-content{float:right}
.main-timeline .timeline-content:before,.main-timeline .timeline:nth-child(even) .timeline-content:before{width:50%;left:120px}
.main-timeline .timeline:nth-child(even) .timeline-content:before{right:auto;transform:rotateY(0)}
.main-timeline .timeline-content:after,.main-timeline .timeline:nth-child(even) .timeline-content:after{left:85px}
}
@media only screen and (max-width:479px){.main-timeline .timeline-content,.main-timeline .timeline:nth-child(2n) .timeline-content{padding-left:110px}
.main-timeline .timeline-content:before,.main-timeline .timeline:nth-child(2n) .timeline-content:before{left:99px}
.main-timeline .timeline-content:after,.main-timeline .timeline:nth-child(2n) .timeline-content:after{left:65px}
}