.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url(//db.onlinewebfonts.com/c/7d1c0df7a7b61f4189d0ca451f707db0?family=Eras+Medium+ITC);

@font-face {
	font-family: 'SF Pro Display Regular';
	font-style: normal;
	font-weight: normal;
	src: local('SF Pro Display Regular'), url('./fonts/SFPRODISPLAYREGULAR.woff') format('woff');
}


@font-face {
	font-family: 'SF Pro Display Ultralight Italic';
	font-style: normal;
	font-weight: normal;
	src: local('SF Pro Display Ultralight Italic'),
	url('./fonts/SFPRODISPLAYULTRALIGHTITALIC.woff') format('woff');
}


@font-face {
	font-family: 'SF Pro Display Thin Italic';
	font-style: normal;
	font-weight: normal;
	src: local('SF Pro Display Thin Italic'),
	url('./fonts/SFPRODISPLAYTHINITALIC.woff') format('woff');
}


@font-face {
	font-family: 'SF Pro Display Light Italic';
	font-style: normal;
	font-weight: normal;
	src: local('SF Pro Display Light Italic'),
	url('./fonts/SFPRODISPLAYLIGHTITALIC.woff') format('woff');
}


@font-face {
	font-family: 'SF Pro Display Medium';
	font-style: normal;
	font-weight: normal;
	src: local('SF Pro Display Medium'),
	url('./fonts/SFPRODISPLAYMEDIUM.woff') format('woff');
}


@font-face {
	font-family: 'SF Pro Display Semibold Italic';
	font-style: normal;
	font-weight: normal;
	src: local('SF Pro Display Semibold Italic'),
	url('./fonts/SFPRODISPLAYSEMIBOLDITALIC.woff') format('woff');
}


@font-face {
	font-family: 'SF Pro Display Bold';
	font-style: normal;
	font-weight: normal;
	src: local('SF Pro Display Bold'),
	url('./fonts/SFPRODISPLAYBOLD.woff') format('woff');
}


@font-face {
	font-family: 'SF Pro Display Heavy Italic';
	font-style: normal;
	font-weight: normal;
	src: local('SF Pro Display Heavy Italic'),
	url('./fonts/SFPRODISPLAYHEAVYITALIC.woff') format('woff');
}


@font-face {
	font-family: 'SF Pro Display Black Italic';
	font-style: normal;
	font-weight: normal;
	src: local('SF Pro Display Black Italic'),
	url('./fonts/SFPRODISPLAYBLACKITALIC.woff') format('woff');
}

body {
	font-family: 'Poppins',
	sans-serif;
}

.App-header {
  color: white;
	min-height: 50vh;
	text-align: left;
	min-width: 60vh;
}

.App-header-page {
	color: white;
	min-height: 30vh;
	text-align: left;
	min-width: 60vh;
}

.home-img-padding{
	padding-bottom: 20px;
}

.App-header-logo {
	/* background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin); */
	background-color: white;
	color: white;
	min-height: 10vh;
	padding-bottom: 1rem;
	text-align: center;
	min-width: 60vh;
	top: 10px;
}

.App-container-min{
	padding-top:0em;
	padding-bottom: 1em;
}

.menu-max-contain{
	padding-left: 150px;
}

.app-home{
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.App-about{
	background-color: #efeff0;
}

.App-menu{
	background-color: #f8f9fa;
}

.App-footer{
	/* background-color: #f8f9fa; */
	color: black;
	min-height: 20vh;
	padding-top: 1rem;
}

.App-container{
	min-height: 30vh;
	padding: 1rem 4rem;
	text-align: center;
	min-width: 60vh;
}

.App-link {
  color: #61dafb;
}

.affix {
	top: 0;
	width: 100%;
}

.affix+.container-fluid {
	padding-top: 70px;
}

.short-div {
	height: 60px;
	text-align: right;
}

/*-----Home------*/
.home-card{
	min-height: 500px;
}

/*-----Header------*/
.img-logo {
	display: block;
	max-width: 100%;
	min-width: 20vh;
}

.footer-fb-logo{
	margin-bottom: 3px;
}

/*-----Contact------*/
.contact-map{
	margin-top: 10px;
}

.contact-email-form{
	margin:20px;
	text-align: left;
}

/*-----Menu------*/
.menu-search-popup{
	z-index: 1;
}

.img-logo-menu {
	display: inline-block;
	white-space: nowrap;
	max-width: 20%;
}

.block-logo-menu {
	background-color: #b2babb;
	align-items: center;
	box-shadow: 5px 5px 8px #888888;
}

.menu-popup{
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	/* IE 8 */
	filter: alpha(opacity=100);
	/* IE 5-7 */
	-moz-opacity: 1;
	/* Netscape */
	-khtml-opacity: 1;
	/* Safari 1.x */
	opacity: 1;
	/* Good browsers */
}

.menu-product{
	margin-top: 20px;
}

/*-----Footer------*/
.footer-social-icon{
	padding-bottom: 1rem;
}

.footer-copyright{
	padding-top: 1rem;
	padding-bottom: 2rem;
}

/*-----Button------*/
.btn_gettouch {
	position: fixed !important;
	width: 70px;
	max-height: 90px;
	top: 40%;
	right: 0px;
	font-size: 12px;
	/* padding: 10px 17px; */
	text-align: center;
	z-index: 1;
}

.btn_gotop {
	position: fixed !important;
	width: 50px;
	height: 50px;
	bottom: 2%;
	right: 15px;
	font-size: 12px;
	/* padding: 10px 17px; */
	text-align: center;
	z-index: 1;
}

.navbar-nav>li {
	margin-left: 15px;
	margin-right: 15px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a:link,
a:visited {
	text-decoration: none;
}

.products-container{
	text-align: center;
	min-width: 400px;
	margin-bottom: 20px;
}

.modalPopup-div{
	text-align: left;
}

.header-font-head{
	font-size: 50px;
}

.products-group-menu{
	min-height: 110px;
	min-width: 100px;
	margin: 10px;
}

.card-bg-collor{
	background-color: #8fbc8f;
	border-radius:2%;
}

.hyperlink-color{
	color: black;
}

.hyperlink-product {
	color: black;
}

.menu-bg-color{
	background-color: #edeceb;
	border-radius:10px;
	min-height: 50px;
}

.popovermenu-box-size{
	width: 70px;
	height: 77px;
	background-color: #d2d0ce;
	display: block;
	padding: 12px 0;
}
.popovermenu-box-size-feedback {
	width: 70px;
	height: 70px;
	background-color: #40bf80;
	display: block;
	padding: 12px 0;
}
.home-cer-header{
	align-items: center;
	text-align: center;
}
.home-cer-1{
	max-width: 65%;
	align-items: center;
	text-align: center;
}
.home-min-width{
	min-width: 200px;
	padding-top: 20px;
}
.home-min-width-1{
	min-width: 320px;
	padding-top: 20px;
}
.home-min-width-2{
	min-width: 170px;
	padding-top: 20px;
}
.home-min-width-3{
	min-width: 350px;
	padding-top: 20px;
}

.header-content-indent{
	text-indent: 50px;
}

.header-content-bold{
	font-weight: 700;
	font-family: 'Eras Medium ITC';
}

.header-content-border{
	padding: 10px;
	padding-left: 30px;
	border-radius: 60px 20px;
	border-radius: 60px 20px;
	background-color: cadetblue;
	opacity: 0.9;
}

.home-header-content {
	padding-left: 5px;
	border-radius: 0px 0px 30px 0px;
	background-color: cadetblue;
	opacity: 0.9;
}

.home-bold{
	font-weight: bold;
	font-size: 24px;
}

#nav {
	background-color: #333;
	position: fixed;
	left: 0;
	width: 100%;
	display: block;
	transition: top 0.5s;
	z-index: 50;
}




